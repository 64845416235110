import { Fab, LinearProgress, styled } from '@mui/material';

import { ThemeProvider } from '/common';
import { IWithCSS, ThemeMode } from '/common/models';

export const BlockContainer = styled('div')<{ $error: boolean } & IWithCSS>`
  position: relative;
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  border: 1px solid ${({ theme, $error }) => ($error ? theme.palette.error.main : theme.palette.divider)};
  padding: ${({ theme }) => theme.spacing(2)};
  ${({ css }) => css}
`;

export const StyledLabel = styled('label')<{ $error: boolean } & IWithCSS>`
  padding: 0 5px;
  background-color: ${({ theme }) => theme.palette.background.paper};
  text-align: center;
  color: ${({ theme, $error }) =>
    $error
      ? theme.palette.error.main
      : ThemeProvider.getAppropriateStyles(
          theme.palette.mode as ThemeMode,
          theme.palette.primary.main,
          theme.palette.text.primary
        )};
  position: absolute;
  top: ${({ theme }) => `-${theme.spacing(1.5)}`};
  left: ${({ theme }) => theme.spacing(2)};
  height: ${({ theme }) => theme.spacing(3)};
  display: ${({ $error }) => ($error ? 'flex' : 'block')};
  align-items: ${({ $error }) => ($error ? 'center' : 'inherit')};

  ${({ css }) => css}
`;

export const BlockActions = styled('span')<IWithCSS>`
  position: absolute;
  top: ${({ theme }) => `-${theme.spacing(2)}`};
  right: ${({ theme }) => theme.spacing(1)};

  ${({ css }) => css}
`;

export const BlockContent = styled('div')<IWithCSS>`
  ${({ css }) => css}
`;

export const StyledSpinner = styled(LinearProgress)<IWithCSS>`
  position: absolute;
  top: -1px;
  left: -1px;
  right: -1px;
  border-radius: ${({ theme }) => theme.shape.borderRadius}px ${({ theme }) => theme.shape.borderRadius}px 0 0;
  ${({ css }) => css}
`;

export const StyledButton = styled(Fab)<IWithCSS>`
  ${({ css }) => css}
`;

export const Wrapper = styled('span', {
  shouldForwardProp: (prop) => prop !== 'isLast',
})<{ isLast: boolean }>`
  background-color: ${({ theme }) => theme.palette.background.paper};
  border-radius: 50%;
  ${({ theme, isLast }) => (isLast ? `margin-left: ${theme.spacing(1)};` : '')}
`;
