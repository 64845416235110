import { FC, memo, useEffect } from 'react';
import { IconButton } from '@mui/material';
import { QueryKey, useQueryClient, QueryClient } from '@tanstack/react-query';
import { StarBorder as StarBorderIcon, Star as StarIcon } from '@mui/icons-material';

import { AppID, SpaceID } from '@playq/octopus-common';
import { QueryAppsResponse, QuerySpacesResponse } from '@playq/octopus2-apps';

import { IApps, ICurrentApp, useAddFavourite, useRemoveFavourite } from '/api';

interface IAppsContentProps {
  absolute?: boolean;
  isFavourite: boolean;
  onRefetch?: (params: { cancelRefetch: boolean }) => void;
  app: IApps;
  queryKey: QueryKey;
  currentApp: ICurrentApp;
}

export function updateFavouriteStatus({
  id,
  queryClient,
  queryKey,
}: {
  id: AppID | SpaceID;
  queryClient: QueryClient;
  queryKey: QueryKey;
}) {
  queryClient.setQueryData(queryKey, (previousTableData: QueryAppsResponse | QuerySpacesResponse | undefined) => {
    const idSerialized = id.serialize();
    if (!previousTableData) return previousTableData;
    previousTableData.features[idSerialized].isFavourite = !previousTableData.features[idSerialized].isFavourite;
    return previousTableData;
  });
}

const starColor = '#f4c867';
export const FavoriteButton: FC<IAppsContentProps> = memo(
  ({ absolute = false, isFavourite, onRefetch, app, queryKey, currentApp }) => {
    const queryClient = useQueryClient();

    const { mutate: addFavourite, status } = useAddFavourite(currentApp, {
      onMutate(variables) {
        return updateFavouriteStatus({ id: variables.app.id, queryClient, queryKey: queryKey });
      },
    });

    const { mutate: removeFavourite, status: removeStatus } = useRemoveFavourite(currentApp, {
      onMutate(variables) {
        return updateFavouriteStatus({ id: variables.app.id, queryClient, queryKey: queryKey });
      },
    });

    const isSuccessful = status === 'success' || removeStatus === 'success';
    useEffect(() => {
      if (!isSuccessful) return;
      if (onRefetch) {
        onRefetch({ cancelRefetch: false });
      } else {
        queryClient.invalidateQueries({ queryKey }, { cancelRefetch: false });
      }
    }, [status, removeStatus, onRefetch, queryClient, queryKey, isSuccessful]);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      const mutationFn = isFavourite ? removeFavourite : addFavourite;
      mutationFn({ app });
    };

    return (
      <span style={absolute ? { position: 'absolute', left: '-45px' } : {}}>
        <IconButton onClick={handleClick} sx={!isFavourite ? { ':hover': { color: starColor } } : {}}>
          {isFavourite ? <StarIcon sx={{ color: starColor }} /> : <StarBorderIcon />}
        </IconButton>
      </span>
    );
  }
);
